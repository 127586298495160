
html {
    scroll-behavior: smooth;
    direction: rtl;
    height: calc(100vh - 80px);
    box-sizing: border-box;
}

body {
  margin: 0;
  height: calc(100vh - 80px);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
    text-align: center;
  }
  
  .App-logo {
    height: 50vmin;
  }
  
  .App-link {
    color: #09d3ac;
  }
  
  a {
      text-decoration: none;
      color: unset;
  }